<template>
  <div class="mt-n5">
    <defaultFieldTypes
      :fieldAttributes="fieldAttributes"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :checkReadOnly="disabled"
      :value="value"
      :valueLine="valueLine"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  props: [
    "field",
    "fieldAttributes",
    "value",
    "valueLine",
    "hideDetails",
    "result",
  ],
  components: {
    defaultFieldTypes,
  },
  data() {
    return { disabled: false };
  },
  watch: {
    "valueLine.projectName": function (newVal) {
     this.checkName(newVal);
   
    },
  },
  created(){
    this.checkName(this.valueLine.projectName)
  },

  methods:{

checkName(newVal){
     if ((typeof newVal != "undefined" && newVal != ""  && isNaN(newVal))||newVal == 0) {
        this.disabled = false;
          this.$emit("input", 1);
      } else {
        this.$emit("input", 0);
        this.disabled = true;
      }
}

  }
};
</script>